import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/homepage'),
    meta: {
      titleName: 'Home'
    }
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('../views/faqs'),
    meta: {
      titleName: 'FAQ'
    }
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import('../views/buy'),
    meta: {
      titleName: 'Checkout'
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/success'),
    meta: {
      titleName: 'Thank you'
    }
  },
  {
    path: '/privacypolicy',
    name: 'privacy',
    component: () => import('../views/page-privacy')
  },
  // {
  //   path: '/contact',
  //   name: 'contact-us',
  //   component: () => import('../views/contact-us')
  // },
  {
    path: '*',
    component: () => import('../views/page-error'),
    meta: {
      titleName: '404'
    }
  },





//next - not used
  {
    path: '/index-saas',
    name: 'Saas',
    component: () => import('../views/examples/index-saas')
  },
  {
    path: '/index-classic-saas',
    name: 'classic-saas',
    component: () => import('../views/examples/home')
  },
  {
    path: '/index-agency',
    name: 'agency',
    component: () => import('../views/examples/index-agency')
  },
  {
    path: '/old',
    name: 'HomeOld',
    component: () => import('../views/examples')
  },
  {
    path: '/index-apps',
    name: 'apps',
    component: () => import('../views/examples/index-apps')
  },
  {
    path: '/index-classic-app',
    name: 'classic-apps',
    component: () => import('../views/examples/index-classic-app')
  },
  {
    path: '/index-studio',
    name: 'studio',
    component: () => import('../views/examples/index-studio')
  },
  {
    path: '/index-business',
    name: 'business',
    component: () => import('../views/examples/index-business')
  },
  {
    path: '/index-modern-business',
    name: 'modern-business',
    component: () => import('../views/examples/index-modern-business')
  },
  {
    path: '/index-hotel',
    name: 'hotel',
    component: () => import('../views/examples/index-hotel')
  },
  {
    path: '/index-marketing',
    name: 'marketing',
    component: () => import('../views/examples/index-marketing')
  },
  {
    path: '/index-enterprise',
    name: 'enterprise',
    component: () => import('../views/examples/index-enterprise')
  },
  {
    path: '/index-insurance',
    name: 'insurance',
    component: () => import('../views/examples/index-insurance')
  },
  {
    path: '/index-shop',
    name: 'shop',
    component: () => import('../views/examples/index-shop')
  },
  {
    path: '/index-coworking',
    name: 'coworking',
    component: () => import('../views/examples/index-coworking')
  },
  {
    path: '/index-it-solution',
    name: 'it-solution',
    component: () => import('../views/examples/index-it-solution')
  },
  {
    path: '/index-corporate',
    name: 'corporate',
    component: () => import('../views/examples/index-corporate')
  },
  {
    path: '/index-task-management',
    name: 'task-management',
    component: () => import('../views/examples/index-task-management')
  },
  {
    path: '/index-email-inbox',
    name: 'email-inbox',
    component: () => import('../views/examples/index-email-inbox')
  },
  {
    path: '/index-landing-one',
    name: 'landing-one',
    component: () => import('../views/examples/index-landing-one')
  },
  {
    path: '/index-landing-two',
    name: 'landing-two',
    component: () => import('../views/examples/index-landing-two')
  },
  {
    path: '/index-landing-three',
    name: 'landing-three',
    component: () => import('../views/examples/index-landing-three')
  },
  {
    path: '/index-travel',
    name: 'travel',
    component: () => import('../views/examples/index-travel')
  },
  {
    path: '/index-blog',
    name: 'blog',
    component: () => import('../views/examples/index-blog')
  },
  {
    path: '/forums',
    name: 'forums',
    component: () => import('../views/examples/forums')
  },
  {
    path: '/index-personal',
    name: 'personal',
    component: () => import('../views/examples/index-personal')
  },
  {
    path: '/index-services',
    name: 'services',
    component: () => import('../views/examples/index-services')
  },
  {
    path: '/index-payments',
    name: 'payments',
    component: () => import('../views/examples/index-payments')
  },
  {
    path: '/index-crypto',
    name: 'crypto',
    component: () => import('../views/examples/index-crypto')
  },
  {
    path: '/index-course',
    name: 'course',
    component: () => import('../views/examples/index-course')
  },
  {
    path: '/index-online-learning',
    name: 'online-learning',
    component: () => import('../views/examples/index-online-learning')
  },
  {
    path: '/index-hosting',
    name: 'hosting',
    component: () => import('../views/examples/index-hosting')
  },
  {
    path: '/index-event',
    name: 'event',
    component: () => import('../views/examples/index-event')
  },
  {
    path: '/index-single-product',
    name: 'single-product',
    component: () => import('../views/examples/index-single-product')
  },
  {
    path: '/index-portfolio',
    name: 'portfolio',
    component: () => import('../views/examples/index-portfolio')
  },
  {
    path: '/index-developer',
    name: 'developer',
    component: () => import('../views/examples/index-developer')
  },
  {
    path: '/index-seo-agency',
    name: 'index-seo-agency',
    component: () => import('../views/examples/index-seo-agency')
  },
  {
    path: '/index-construction',
    name: 'index-construction',
    component: () => import('../views/examples/index-construction')
  },
  {
    path: '/index-real-estate',
    name: 'index-real-estate',
    component: () => import('../views/examples/index-real-estate')
  },
  {
    path: '/index-integration',
    name: 'index-integration',
    component: () => import('../views/examples/index-integration')
  },
  {
    path: '/index-landing-four',
    name: 'index-landing-four',
    component: () => import('../views/examples/index-landing-four')
  },
  {
    path: '/index-hospital',
    name: 'index-hospital',
    component: () => import('../views/examples/index-hospital')
  },
  {
    path: '/index-job',
    name: 'job',
    component: () => import('../views/examples/index-job')
  },
  {
    path: '/index-social-marketing',
    name: 'social-marketing',
    component: () => import('../views/examples/index-social-marketing')
  },
  {
    path: '/index-digital-agency',
    name: 'digital-agency',
    component: () => import('../views/examples/index-digital-agency')
  },
  {
    path: '/index-car-riding',
    name: 'car-riding',
    component: () => import('../views/examples/index-car-riding')
  },
  {
    path: '/index-customer',
    name: 'customer',
    component: () => import('../views/examples/index-customer')
  },
  {
    path: '/index-software',
    name: 'software',
    component: () => import('../views/examples/index-software')
  },
  {
    path: '/index-ebook',
    name: 'ebook',
    component: () => import('../views/examples/index-ebook')
  },
  {
    path: '/index-onepage',
    name: 'onepage',
    component: () => import('../views/examples/index-onepage')
  },
  {
    path: '/page-aboutus',
    name: 'aboutus',
    component: () => import('../views/examples/page-aboutus')
  },
  {
    path: '/page-aboutus-two',
    name: 'aboutus-two',
    component: () => import('../views/examples/page-aboutus-two')
  },
  {
    path: '/page-services',
    name: 'page-services',
    component: () => import('../views/examples/page-services')
  },
  {
    path: '/page-job-candidate-list',
    name: 'page-job-candidate-list',
    component: () => import('../views/examples/page-job-candidate-list')
  },
  {
    path: '/page-history',
    name: 'history',
    component: () => import('../views/examples/page-history')
  },
  {
    path: '/page-team',
    name: 'team',
    component: () => import('../views/examples/page-team')
  },
  {
    path: '/page-pricing',
    name: 'pricing',
    component: () => import('../views/examples/page-pricing')
  },
  {
    path: '/account-profile',
    name: 'account-profile',
    component: () => import('../views/examples/account-profile')
  },
  {
    path: '/account-members',
    name: 'account-members',
    component: () => import('../views/examples/account-members')
  },
  {
    path: '/account-works',
    name: 'account-works',
    component: () => import('../views/examples/account-works')
  },
  {
    path: '/account-messages',
    name: 'account-messages',
    component: () => import('../views/examples/account-messages')
  },
  {
    path: '/account-payments',
    name: 'account-payments',
    component: () => import('../views/examples/account-payments')
  },
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('../views/examples/account-setting')
  },
  {
    path: '/page-invoice',
    name: 'page-invoice',
    component: () => import('../views/examples/page-invoice')
  },
  {
    path: '/shop-grids',
    name: 'shop-grids',
    component: () => import('../views/examples/shop-grids')
  },
  {
    path: '/shop-product-detail',
    name: 'shop-product-detail',
    component: () => import('../views/examples/shop-product-detail')
  },
  {
    path: '/shop-cart',
    name: 'shop-cart',
    component: () => import('../views/examples/shop-cart')
  },
  {
    path: '/shop-lists',
    name: 'shop-lists',
    component: () => import('../views/examples/shop-lists')
  },

  {
    path: '/shop-myaccount',
    name: 'shop-myaccount',
    component: () => import('../views/examples/shop-myaccount')
  },
  {
    path: '/helpcenter-overview',
    name: 'helpcenter-overview',
    component: () => import('../views/examples/helpcenter-overview')
  },

  {
    path: '/helpcenter-guides',
    name: 'helpcenter-guides',
    component: () => import('../views/examples/helpcenter-guides')
  },
  {
    path: '/helpcenter-support-request',
    name: 'helpcenter-support-request',
    component: () => import('../views/examples/helpcenter-support-request')
  },
  {
    path: '/forums-topic',
    name: 'forums-topic',
    component: () => import('../views/examples/forums-topic')
  },
  {
    path: '/forums-comments',
    name: 'forums-comments',
    component: () => import('../views/examples/forums-comments')
  },
  {
    path: '/email-alert',
    name: 'email-alert',
    component: () => import('../views/examples/email-alert')
  },
  {
    path: '/email-invoice',
    name: 'email-invoice',
    component: () => import('../views/examples/email-invoice')
  },
  {
    path: '/email-confirmation',
    name: 'email-confirmation',
    component: () => import('../views/examples/email-confirmation')
  },
  {
    path: '/email-password-reset',
    name: 'email-password-reset',
    component: () => import('../views/examples/email-password-reset')
  },
  {
    path: '/page-jobs',
    name: 'jobs',
    component: () => import('../views/examples/page-jobs')
  },
  {
    path: '/page-job-apply',
    name: 'job-apply',
    component: () => import('../views/examples/page-job-apply')
  },
  {
    path: '/page-job-candidate',
    name: 'job-candidate',
    component: () => import('../views/examples/page-job-candidate')
  },
  {
    path: '/page-job-company-list',
    name: 'page-job-company-list',
    component: () => import('../views/examples/page-job-company-list')
  },
  {
    path: '/page-job-company',
    name: 'job-company',
    component: () => import('../views/examples/page-job-company')
  },
  {
    path: '/page-job-detail',
    name: 'job-detail',
    component: () => import('../views/examples/page-job-detail')
  },
  {
    path: '/page-jobs-sidebar',
    name: 'jobs-sidebar',
    component: () => import('../views/examples/page-jobs-sidebar')
  },
  {
    path: '/page-blog-grid',
    name: 'blog-grid',
    component: () => import('../views/examples/page-blog-grid')
  },
  {
    path: '/page-blog-sidebar',
    name: 'blog-sidebar',
    component: () => import('../views/examples/page-blog-sidebar')
  },
  {
    path: '/page-blog-list',
    name: 'blog-list',
    component: () => import('../views/examples/page-blog-list')
  },
  {
    path: '/page-blog-list-sidebar',
    name: 'blog-list-sidebar',
    component: () => import('../views/examples/page-blog-list-sidebar')
  },
  {
    path: '/page-blog-detail',
    name: 'blog-detail',
    component: () => import('../views/examples/page-blog-detail')
  },
  {
    path: '/page-blog-detail-two',
    name: 'blog-detail-two',
    component: () => import('../views/examples/page-blog-detail-two')
  },
  {
    path: '/page-cases',
    name: 'cases',
    component: () => import('../views/examples/page-cases')
  },
  {
    path: '/page-case-detail',
    name: 'case-detail',
    component: () => import('../views/examples/page-case-detail')
  },
  {
    path: '/page-portfolio-detail',
    name: 'portfolio-detail',
    component: () => import('../views/examples/page-portfolio-detail')
  },
  {
    path: '/page-portfolio-modern',
    name: 'portfolio-modern',
    component: () => import('../views/examples/page-portfolio-modern')
  },
  {
    path: '/page-portfolio-classic',
    name: 'portfolio-classic',
    component: () => import('../views/examples/page-portfolio-classic')
  },
  {
    path: '/page-portfolio-grid',
    name: 'portfolio-grid',
    component: () => import('../views/examples/page-portfolio-grid')
  },
  {
    path: '/page-portfolio-masonry',
    name: 'portfolio-masonry',
    component: () => import('../views/examples/page-portfolio-masonry')
  },
  {
    path: '/auth-login',
    name: 'login',
    component: () => import('../views/examples/auth-login')
  },
  {
    path: '/auth-signup',
    name: 'signup',
    component: () => import('../views/examples/auth-signup')
  },
  {
    path: '/auth-re-password',
    name: 'reset-password',
    component: () => import('../views/examples/auth-re-password')
  },
  {
    path: '/auth-cover-login',
    name: 'cover-login',
    component: () => import('../views/examples/auth-cover-login')
  },
  {
    path: '/auth-cover-signup',
    name: 'cover-signup',
    component: () => import('../views/examples/auth-cover-signup')
  },
  {
    path: '/auth-cover-re-password',
    name: 'cover-reset-password',
    component: () => import('../views/examples/auth-cover-re-password')
  },
  {
    path: '/auth-login-three',
    name: 'login-three',
    component: () => import('../views/examples/auth-login-three')
  },
  {
    path: '/auth-signup-three',
    name: 'signup-three',
    component: () => import('../views/examples/auth-signup-three')
  },
  {
    path: '/auth-re-password-three',
    name: 'reset-password-three',
    component: () => import('../views/examples/auth-re-password-three')
  },
  {
    path: '/page-terms',
    name: 'terms',
    component: () => import('../views/examples/page-terms')
  },
  {
    path: '/page-comingsoon',
    name: 'comingsoon',
    component: () => import('../views/examples/page-comingsoon')
  },
  {
    path: '/page-comingsoon2',
    name: 'comingsoon2',
    component: () => import('../views/examples/page-comingsoon2')
  },
  {
    path: '/page-maintenance',
    name: 'maintenance',
    component: () => import('../views/examples/page-maintenance')
  },
  {
    path: '/page-error',
    name: 'error',
    component: () => import('../views/page-error')
  },
  {
    path: '/page-thankyou',
    name: 'thankyou',
    component: () => import('../views/examples/page-thankyou')
  },
  {
    path: '/page-contact-detail',
    name: 'contact-detail',
    component: () => import('../views/examples/page-contact-detail')
  },
  {
    path: '/page-contact-one',
    name: 'contact-one',
    component: () => import('../views/examples/page-contact-one')
  },
  {
    path: '/page-contact-two',
    name: 'contact-two',
    component: () => import('../views/examples/page-contact-two')
  },
  {
    path: '/page-contact-three',
    name: 'contact-three',
    component: () => import('../views/examples/page-contact-three')
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import('../views/examples/documentation')
  },
  {
    path: '/changelog',
    name: 'changelog',
    component: () => import('../views/examples/changelog')
  },
  {
    path: '/widget',
    name: 'widget',
    component: () => import('../views/examples/widget')
  },
  {
    path: '/components',
    name: 'components',
    component: () => import('../views/examples/components')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/support')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to, from, savedPosition) {
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: 'smooth',
        }
      } else {
        return { x: 0, y: 0 }
      }
  },
})

export default router
