<script>
export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    }
  },
  mounted() {
    document.title = this.title;
  },
  watch: {
    $route (from){
      document.title = from.meta.titleName ? from.meta.titleName + ' - ' + this.title : this.title;
    },
  }
}
</script>

<template>
<div id="app">
    <router-view />
</div>
</template>

<style lang="scss">
</style>
